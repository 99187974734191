import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { deactivateManager, selectOpenDeleteManagerDialog, updateOpenDeleteManagerDialog } from '../../redux/slices/managerSlice';

export default function DeleteManagerDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenDeleteManagerDialog)

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenDeleteManagerDialog(false)) }}
            >
                <DialogContent>
                    <Typography variant='h6' sx={{ marginBottom: 1 }}>{"Deactivate Manager"}</Typography>

                    <Typography variant='body2'>{"Are you sure you want to deactivate this manager"}</Typography>

                    <Box sx={{ display: 'flex' }} mt={3}>
                        <Button variant='contained' color='error' sx={{ marginRight: 1 }} onClick={() => { dispatch(deactivateManager()) }}>Deactivate</Button>
                        <Button variant='contained' onClick={() => { dispatch(updateOpenDeleteManagerDialog(false)) }}>Cancel</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}