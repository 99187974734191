import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  dashboardStats: null,
};

export const loadDashboardStats = createAsyncThunk(
  'dashboard/loadDashboardStats',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {
        filter: payload.filter
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/dashboard/stats`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateDashboardStats(response.data.payload))
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateDashboardStats: (state, action) => {
      state.dashboardStats = action.payload;
    },
  }
})

export const {
  updateDashboardStats,
} = dashboardSlice.actions;

export const selectDashboardStats = (state) => state.dashboard.dashboardStats;


export default dashboardSlice.reducer;
