import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Box,
  Card, Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Stack
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllAirports, selectPagesCount, updateOpenEditAirportDialog, updateSelectedAirport } from '../../redux/slices/airportSlice';
import { formatTime } from '../../utils/formatTime';
import { useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';


function MoreMenuButton({ data }) {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch()

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <IconButton ref={menuRef} size="large" onClick={handleOpen}>
          <MoreVertIcon width={20} height={20} />
        </IconButton>
      </>

      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => { dispatch(updateSelectedAirport(data)); dispatch(updateOpenEditAirportDialog(true)) }}>
          <EditIcon width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => { navigate(`/dashboard/airportprofile/${data._id}`) }}>
          <RemoveRedEyeIcon width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            View Profile
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

function AirportsTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const theme = useTheme();
  const allAirports = useSelector(selectAllAirports)
  const pagesCount = useSelector(selectPagesCount)

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>ICAO</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {allAirports.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row._id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.city}</TableCell>
                <TableCell>{row.ICAO}</TableCell>
                <TableCell>{formatTime(row.createdAt)}</TableCell>

                <TableCell align="right">
                  <Stack direction='row' spacing={2}>
                    <Button startIcon={<EditIcon />} variant='contained' onClick={() => { dispatch(updateSelectedAirport(row)); dispatch(updateOpenEditAirportDialog(true)) }}>
                      Edit
                    </Button>
                    <Button startIcon={<RemoveRedEyeIcon />} variant='contained' onClick={() => { navigate(`/dashboard/airportprofile/${row._id}`) }}>
                      View Profile
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />

      {
        pagesCount > 1 && <Box p={2}>
          <Pagination count={10} color="primary" />
        </Box>
      }
    </Card>
  );
}

export default AirportsTable;
