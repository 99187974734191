import { configureStore } from '@reduxjs/toolkit';
import airportReducer from './slices/airportSlice';
import dashboardReducer from './slices/dashboardSlice';
import managerReducer from './slices/managerSlice';
import singleAirportReducer from './slices/singleAirportSlice';

export const store = configureStore({
  reducer: {
    manager: managerReducer,
    airport: airportReducer,
    singleAirport: singleAirportReducer,
    dashboard: dashboardReducer
  },
});
