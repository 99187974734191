import {
  Box,
  Card, Divider, Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllManagers,
  selectManagersTablepage,
  selectManagersTablepagesCount
} from "../../redux/slices/singleAirportSlice";
import { formatTime } from '../../utils/formatTime';
import Label from "../Label";


function SingleAirportManagersTable() {
  const allManagers = useSelector(selectAllManagers)
  const page = useSelector(selectManagersTablepage)
  const pagesCount = useSelector(selectManagersTablepagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created on</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {allManagers.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.terminals.map(terminal => {
                    if (row.terminals.length !== 1) {
                      return terminal.name + ' | '
                    } else {
                      return terminal.name
                    }
                  })}</TableCell>
                  <TableCell>
                    <Label
                      variant="filled"
                      color={
                        (row.isActive === false && "error") ||
                        "success"
                      }
                    >
                      {row.isActive === true ? 'Active' : 'Deactivated'}
                    </Label>
                  </TableCell>
                  <TableCell>{formatTime(row.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default SingleAirportManagersTable;
