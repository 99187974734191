import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  airportInfo: [],
  allManagers: [],
  allTerminals: [],
  callsData: null,
  sessionsData: [],
  selectedSession: null,
  feedbacksData: [],

  // Pagination
  managersTablepage: 1,
  managersTablepagesCount: 1,
  terminalsTablepage: 1,
  terminalsTablepagesCount: 1,
  callsTablepage: 1,
  callsTablepagesCount: 1,
  sessionsTablepage: 1,
  sessionsTablepagesCount: 1,

  // Dialog
  openCreateNewTerminalDialog: false,
  openSessionLogsDialog: false,
};

export const createTerminal = createAsyncThunk(
  'singleAirport/createTerminal',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/admin/terminal/create/${payload.airportId}`, {
      'name': payload.name
    }, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenCreateNewTerminalDialog(false))
      thunkAPI.dispatch(loadAllTerminals({ 'airportId': payload.airportId }))
      thunkAPI.dispatch(loadAirportInfo({ 'airportId': payload.airportId }))
    }
  }
);


export const loadAirportInfo = createAsyncThunk(
  'singleAirport/loadAirportInfo',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/read/${payload.airportId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAirportInfo(response.data.payload))
    }
  }
);

export const loadAllManagers = createAsyncThunk(
  'singleAirport/loadAllManagers',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/readmanagers/${payload.airportId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllManagers(response.data.payload))
    }
  }
);

export const loadAllTerminals = createAsyncThunk(
  'singleAirport/loadAllTerminals',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/readterminals/${payload.airportId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllTerminals(response.data.payload))
    }
  }
);

export const loadCallsData = createAsyncThunk(
  'singleAirport/loadCallsData',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")
    const pageSize = 10

    let config = {
      params: {
        filter: payload.filter,
        page: thunkAPI.getState().singleAirport.callsTablepage,
        size: pageSize
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/readcalls/${payload.airportId}/${payload.terminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateCallsData(response.data.payload))
      thunkAPI.dispatch(updateCallsTablepagesCount(Math.ceil((response.data.payload.stats.picked_calls + response.data.payload.stats.dropped_calls) / pageSize)))
    }
  }
);

export const loadSessionsData = createAsyncThunk(
  'singleAirport/loadCallsData',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")
    const pageSize = 10

    let config = {
      params: {
        filter: payload.filter,
        page: thunkAPI.getState().singleAirport.sessionsTablepage,
        size: pageSize
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/readsessions/${payload.airportId}/${payload.terminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateSessionsData(response.data.payload.sessions))
      thunkAPI.dispatch(updateSessionsTablepagesCount(response.data.pager.pagesCount))
      thunkAPI.dispatch(updateSessionsTablepagesCount(Math.ceil((response.data.payload.stats.picked_calls + response.data.payload.stats.dropped_calls) / pageSize)))
    }
  }
);

export const loadFeedbacksData = createAsyncThunk(
  'singleAirport/loadFeedbacksData',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")
    const pageSize = 10

    let config = {
      params: {
        filter: payload.filter,
        page: thunkAPI.getState().singleAirport.callsTablepage,
        size: pageSize
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/readfeedbacks/${payload.airportId}/${payload.terminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateFeedbacksData(response.data.payload.feedbacks))
      // thunkAPI.dispatch(updateCallsTablepagesCount(Math.ceil((response.data.payload.stats.picked_calls + response.data.payload.stats.dropped_calls) / pageSize)))
    }
  }
);

export const singleAirportSlice = createSlice({
  name: 'singleAirport',
  initialState,
  reducers: {
    updateAirportInfo: (state, action) => {
      state.airportInfo = action.payload;
    },
    updateAllManagers: (state, action) => {
      state.allManagers = action.payload;
    },
    updateAllTerminals: (state, action) => {
      state.allTerminals = action.payload;
    },
    updateCallsData: (state, action) => {
      state.callsData = action.payload;
    },
    updateSessionsData: (state, action) => {
      state.sessionsData = action.payload;
    },
    updateFeedbacksData: (state, action) => {
      state.feedbacksData = action.payload;
    },


    // Pagination
    updateCallsTablepage: (state, action) => {
      state.callsTablepage = action.payload;
    },
    updateCallsTablepagesCount: (state, action) => {
      state.callsTablepagesCount = action.payload;
    },
    updateSessionsTablepage: (state, action) => {
      state.sessionsTablepage = action.payload;
    },
    updateSessionsTablepagesCount: (state, action) => {
      state.sessionsTablepagesCount = action.payload;
    },
    updateSelectedSession: (state, action) => {
      state.selectedSession = action.payload;
    },


    // Dialog
    updateOpenCreateNewTerminalDialog: (state, action) => {
      state.openCreateNewTerminalDialog = action.payload;
    },
    updateOpenSessionLogsDialog: (state, action) => {
      state.openSessionLogsDialog = action.payload;
    },
  }
})

export const {
  updateAirportInfo,
  updateAllManagers,
  updateAllTerminals,
  updateCallsData,
  updateSessionsData,
  updateSelectedSession,
  updateFeedbacksData,

  // Pagination
  updateCallsTablepage,
  updateCallsTablepagesCount,
  updateSessionsTablepage,
  updateSessionsTablepagesCount,

  // Dialog
  updateOpenCreateNewTerminalDialog,
  updateOpenSessionLogsDialog
} = singleAirportSlice.actions;

export const selectAirportInfo = (state) => state.singleAirport.airportInfo;
export const selectAllManagers = (state) => state.singleAirport.allManagers;
export const selectAllTerminals = (state) => state.singleAirport.allTerminals;
export const selectCallsData = (state) => state.singleAirport.callsData;
export const selectSessionsData = (state) => state.singleAirport.sessionsData;
export const selectSelectedSession = (state) => state.singleAirport.selectedSession;
export const selectFeedbacksData = (state) => state.singleAirport.feedbacksData;


export const selectManagersTablepage = (state) => state.singleAirport.managersTablepage;
export const selectManagersTablepagesCount = (state) => state.singleAirport.managersTablepagesCount;
export const selectTerminalsTablepage = (state) => state.singleAirport.terminalsTablepage;
export const selectTerminalsTablepagesCount = (state) => state.singleAirport.terminalsTablepagesCount;
export const selectCallsTablepage = (state) => state.singleAirport.callsTablepage;
export const selectCallsTablepagesCount = (state) => state.singleAirport.callsTablepagesCount;
export const selectSessionsTablepage = (state) => state.singleAirport.sessionsTablepage;
export const selectSessionsTablepagesCount = (state) => state.singleAirport.sessionsTablepagesCount;

// Dialog
export const selectOpenCreateNewTerminalDialog = (state) => state.singleAirport.openCreateNewTerminalDialog;
export const selectOpenSessionLogsDialog = (state) => state.singleAirport.openSessionLogsDialog;


export default singleAirportSlice.reducer;
