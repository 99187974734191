import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card, Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllManagers, loadAllTerminals, selectAllManagers, selectPage,
  selectPagesCount,
  updateOpenDeleteManagerDialog,
  updateOpenEditManagerDialog,
  updateSelectedManager,
  activateManager
} from "../../redux/slices/managerSlice";
import { formatTime } from '../../utils/formatTime';
import Label from "../Label";


function MoreMenuButton({ manager, dispatch }) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <IconButton ref={menuRef} size="large" onClick={handleOpen}>
          <MoreVertIcon width={20} height={20} />
        </IconButton>
      </>

      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => { dispatch(updateSelectedManager(manager)); dispatch(updateOpenEditManagerDialog(true))() }}>
          <EditIcon width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Edit
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

function ManagersTable({ socket }) {
  const allManagers = useSelector(selectAllManagers)
  const page = useSelector(selectPage)
  const pagesCount = useSelector(selectPagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();
  const [onlineManagers, setOnlineManagers] = useState([])

  useEffect(() => {
    dispatch(loadAllManagers())
    dispatch(loadAllTerminals())

    socket.on("managersCurrentStatus", (payload) => {
      console.log('managersCurrentStatus')
      console.log(payload.onlineManagers)
      setOnlineManagers(payload.onlineManagers)
    });

    return () => {
      socket.off('managersCurrentStatus')
    }
  }, [])

  const deactivateManager = (manager) => {
    dispatch(updateSelectedManager(manager));
    dispatch(updateOpenDeleteManagerDialog(true));
  }

  const initActivateManager = (manager) => {
    dispatch(updateSelectedManager(manager));
    dispatch(activateManager());
  }

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Airport</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Account Status</TableCell>
                <TableCell>Online/Offline</TableCell>
                <TableCell>Created on</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {allManagers.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.airport?.name}</TableCell>
                  <TableCell>{row.terminals?.map(data => data.name + ' ')}</TableCell>
                  <TableCell>{row.airport?.city}</TableCell>
                  <TableCell>
                    <Switch checked={row.isActive} onChange={(e, value) => {
                      if (!row.isActive) {
                        initActivateManager(row)
                      } else {
                        deactivateManager(row)
                      }
                    }} />
                  </TableCell>
                  <TableCell>
                    <Label
                      variant="filled"
                      color={
                        (onlineManagers.includes(row._id) === false && "error") ||
                        "success"
                      }
                    >
                      {onlineManagers.includes(row._id) === true ? 'Online' : 'Offline'}
                    </Label>
                  </TableCell>
                  <TableCell>{formatTime(row.createdAt)}</TableCell>
                  <TableCell align="right">
                    <MoreMenuButton manager={row} dispatch={dispatch} />
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default ManagersTable;
