import { Box, Card, CardHeader, TextField, Typography } from "@mui/material";
import { merge } from "lodash";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import DefaultChartOptions from "../DefaultChartOptions";
import { loadDashboardStats } from "../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectFeedbacksData } from "../../redux/slices/singleAirportSlice";

function FeedbacksGraph({ dashboardStats }) {
  const dispatch = useDispatch()
  const feedbacksData = useSelector(selectFeedbacksData)

  const handleChangeSeriesData = (event) => {
    dispatch(loadDashboardStats({ filter: event.target.value }))
  };

  return (
    <Card>
      <CardHeader
        title="Feedbacks"
        subheader=""
      />

      {
        feedbacksData.map((feedback, index) => {
          return <Box sx={{ mt: 3, mx: 3 }} key={`feedback_chart_${index}`}>
            <Typography variant='h4'>{feedback.question}</Typography>

            <ReactApexChart
              type="bar"
              series={[
                { name: "This Month", data: feedback.thisMonthsXAxis },
                { name: "Last Month", data: feedback.lastMonthsXAxis },
              ]}
              options={{
                stroke: {
                  show: true,
                  width: 3,
                  colors: ["transparent"],
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels: {
                      position: 'top',
                    },
                  }
                },
                dataLabels: {
                  enabled: true,
                  offsetX: -6,
                  style: {
                    fontSize: '12px',
                    colors: ['#fff']
                  }
                },
                xaxis: {
                  // categories: dashboardStats.callsData.xaxis.categories,
                  categories: feedback.options,
                },
                tooltip: {
                  y: {
                    formatter: (val) => `${val} calls`,
                  },
                },
              }}
              height={360}
            />
          </Box>
        })
      }

    </Card>
  );
}

export default FeedbacksGraph;
