import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  allManagers: [],
  allTerminals: [],
  selectedManager: null,

  // Dialogs
  openCreateNewManagerDialog: false,
  openEditManagerDialog: false,
  openDeleteManagerDialog: false,

  // Pagination
  page: 1,
  pagesCount: 1,
};

export const CreateManager = createAsyncThunk(
  'manager/CreateManager',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      'name': payload.name,
      'email': payload.email,
      'password': payload.password,
      'terminals': payload.terminals
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/admin/manager/create/${payload.airportId}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenCreateNewManagerDialog(false))
      thunkAPI.dispatch(loadAllManagers())
      thunkAPI.dispatch(updateSelectedManager(null))
      payload.resetForm()
    }
  }
);

export const UpdateManager = createAsyncThunk(
  'manager/UpdateManager',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      'name': payload.name,
      'email': payload.email,
      'password': payload.password,
      'airport': payload.airportId,
      'terminals': payload.terminals
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/admin/manager/update/${thunkAPI.getState().manager.selectedManager._id}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenEditManagerDialog(false))
      thunkAPI.dispatch(loadAllManagers())
      thunkAPI.dispatch(updateSelectedManager(null))
      payload.resetForm()
    }
  }
);

export const loadAllManagers = createAsyncThunk(
  'manager/loadAllManagers',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/manager/read`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllManagers(response.data.payload))
    }
  }
);

export const deactivateManager = createAsyncThunk(
  'manager/deactivateManager',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.delete(`${backendServerBaseURL}/admin/manager/deactivate/${thunkAPI.getState().manager.selectedManager._id}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenDeleteManagerDialog(false))
      thunkAPI.dispatch(loadAllManagers())
    }
  }
);

export const activateManager = createAsyncThunk(
  'manager/activateManager',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/admin/manager/activate/${thunkAPI.getState().manager.selectedManager._id}`, {}, config);

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllManagers())
    }
  }
);

// Terminals
export const loadAllTerminals = createAsyncThunk(
  'manager/loadAllTerminals',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/terminal/read`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllTerminals(response.data.payload))
    }
  }
);

export const managerSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateAllManagers: (state, action) => {
      state.allManagers = action.payload;
    },
    updateAllTerminals: (state, action) => {
      state.allTerminals = action.payload;
    },
    updateSelectedManager: (state, action) => {
      state.selectedManager = action.payload;
    },

    // Dialogs
    updateOpenCreateNewManagerDialog: (state, action) => {
      state.openCreateNewManagerDialog = action.payload;
    },
    updateOpenEditManagerDialog: (state, action) => {
      state.openEditManagerDialog = action.payload;
    },
    updateOpenDeleteManagerDialog: (state, action) => {
      state.openDeleteManagerDialog = action.payload;
    }
  }
})

export const {
  updateAllManagers,
  updateAllTerminals,
  updateSelectedManager,

  // Dialog
  updateOpenCreateNewManagerDialog,
  updateOpenEditManagerDialog,
  updateOpenDeleteManagerDialog
} = managerSlice.actions;

export const selectAllManagers = (state) => state.manager.allManagers;
export const selectAllTerminals = (state) => state.manager.allTerminals;
export const selectPage = (state) => state.manager.page;
export const selectPagesCount = (state) => state.manager.pagesCount;

export const selectSelectedManager = (state) => state.manager.selectedManager;

export const selectOpenCreateNewManagerDialog = (state) => state.manager.openCreateNewManagerDialog;
export const selectOpenEditManagerDialog = (state) => state.manager.openEditManagerDialog;
export const selectOpenDeleteManagerDialog = (state) => state.manager.openDeleteManagerDialog;

export default managerSlice.reducer;
