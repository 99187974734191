import {
    Box, Button, Card, CardHeader, Divider, Pagination, Stack, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadCallsData, selectAllTerminals, selectCallsData, selectCallsTablepagesCount, updateCallsTablepage } from "../../redux/slices/singleAirportSlice";
import { formatTime } from "../../utils/formatTime";
import { staticBaseURL } from '../../utils/backendServerBaseURL';

export default function AirportCalls() {
    const dispatch = useDispatch()
    const callsData = useSelector(selectCallsData)
    const allTerminals = useSelector(selectAllTerminals)
    const params = useParams()
    const [terminalId, setTerminalId] = useState('all')
    const [filter, setFilter] = useState('last 7 days')
    const pagesCount = useSelector(selectCallsTablepagesCount)

    const handleFilterChange = (event) => {
        setFilter(event.target.value)
        dispatch(loadCallsData({ 'airportId': params.airportId, terminalId: terminalId, filter: event.target.value }))
    };

    const handleTerminalChange = (event) => {
        setTerminalId(event.target.value)
        dispatch(loadCallsData({ 'airportId': params.airportId, terminalId: event.target.value, filter: filter }))
    };

    const handlePageChange = (event, value) => {
        dispatch(updateCallsTablepage(value))
        dispatch(loadCallsData({ 'airportId': params.airportId, terminalId: terminalId, filter: filter }))
    };

    const CHART_DATA = [callsData?.stats?.picked_calls, callsData?.stats?.dropped_calls];

    const chartOptions = {
        labels: ['Picked calls', 'Dropped calls'],
        tooltip: {
            y: {
                formatter: (val) => `${val} calls`,
            },
        },
        legend: {
            show: true,
            position: 'bottom',
        }
    };

    const formatDuration = (duration) => {
        if ((duration / (1000 * 60)).toString() !== 'NaN') {
            if (duration / (1000 * 60) > 1) {
                return (Math.round((duration / (1000 * 60)) * 100) / 100) + ' Min'
            } else {
                return (Math.round((duration / (1000)) * 100) / 100) + ' Sec'
            }
        } else {
            return '0 sec'
        }
    }

    return (
        <Card>
            <CardHeader
                title="Calls"
                subheader=""
                action={
                    <Stack direction='row' spacing={2}>
                        <TextField
                            select
                            fullWidth
                            SelectProps={{ native: true }}
                            onChange={handleTerminalChange}
                            sx={{
                                marginBottom: 2,
                                width: 150,
                                "& fieldset": { border: "0 !important" },
                                "& select": {
                                    pl: 1,
                                    py: 0.5,
                                    pr: "24px !important",
                                    typography: "subtitle2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 0.75,
                                    bgcolor: "background.neutral",
                                },
                                "& .MuiNativeSelect-icon": {
                                    top: 4,
                                    right: 0,
                                    width: 20,
                                    height: 20,
                                },
                            }}
                        >
                            <option value={'all'} selected>
                                All
                            </option>

                            {
                                allTerminals.map(terminal => {
                                    return <option value={terminal._id}>
                                        {terminal.name}
                                    </option>
                                })
                            }

                        </TextField>

                        <TextField
                            select
                            fullWidth
                            SelectProps={{ native: true }}
                            onChange={handleFilterChange}
                            sx={{
                                width: 150,
                                "& fieldset": { border: "0 !important" },
                                "& select": {
                                    pl: 1,
                                    py: 0.5,
                                    pr: "24px !important",
                                    typography: "subtitle2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 0.75,
                                    bgcolor: "background.neutral",
                                },
                                "& .MuiNativeSelect-icon": {
                                    top: 4,
                                    right: 0,
                                    width: 20,
                                    height: 20,
                                },
                            }}
                        >
                            <option value={'last 24 hours'}>
                                Last 24 hours
                            </option>
                            <option value={'last 7 days'} selected={true}>
                                Last 7 days
                            </option>
                            <option value={'last month'}>
                                Last Month
                            </option>
                            <option value={'this year'} >
                                This year
                            </option>
                        </TextField>
                    </Stack>

                }
            />

            <Box>
                <Box sx={{ marginBottom: 5, display: 'flex', justifyContent: 'center' }}>
                    <ReactApexChart
                        type="pie"
                        series={CHART_DATA}
                        options={chartOptions}
                        height={360}
                        width={500}
                    />
                </Box>


                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Download Recording</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {callsData?.calls.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell>{row._id}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.type}</TableCell>
                                    <TableCell>{formatTime(row.createdAt)}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.status}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{formatDuration(row.duration)}</TableCell>
                                    {
                                        Object.keys(row).includes('recordingFileName') && <a href={staticBaseURL + '/' + row.recordingFileName} target='_blank'>Download</a>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider />

                {
                    pagesCount > 1 && <Box p={2}>
                        <Pagination count={pagesCount} color="primary" onChange={handlePageChange} />
                    </Box>
                }
            </Box>
        </Card>
    );
}
