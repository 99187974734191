import { Dialog, DialogContent, DialogTitle, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectOpenSessionLogsDialog, selectSelectedSession, updateOpenSessionLogsDialog
} from "../../redux/slices/singleAirportSlice";
import { formatTime } from '../../utils/formatTime';

export default function SessionLogsDialog() {
    const selectedSession = useSelector(selectSelectedSession)
    const open = useSelector(selectOpenSessionLogsDialog)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateOpenSessionLogsDialog(false));
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
            onClose={handleClose}
        >
            <DialogTitle>
                <Typography variant='h6' color='text.secondary'>Session Logs</Typography>
                <Typography variant='caption' color='text.secondary'>Call ID - {selectedSession?._id}</Typography>
            </DialogTitle>
            <DialogContent>
                {
                    selectedSession?.actions.map(data => {
                        return <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#ebebeb', borderRadius: 1 }} p={1} mb={1}>
                            <Box sx={{ minHeight: '0.6rem', minWidth: '0.6rem', maxHeight: '0.6rem', maxWidth: '0.6rem', backgroundColor: 'primary.main', borderRadius: 20, marginRight: 1 }}></Box>
                            <Typography variant='body2'>{data.action}</Typography>
                            <Box sx={{ flexGrow: 1 }}></Box>
                            <Typography variant='body2'>{formatTime(data.time)}</Typography>
                        </Box>
                    })
                }
            </DialogContent>
        </Dialog>
    )
}
