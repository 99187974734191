import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
    Alert, Box, Checkbox, FormControl, IconButton,
    InputLabel, ListItemText, MenuItem, OutlinedInput,
    Select, Stack, TextField,
    Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { selectAllAirports } from '../../redux/slices/airportSlice';
import { CreateManager, selectAllTerminals, selectOpenCreateNewManagerDialog, updateOpenCreateNewManagerDialog } from '../../redux/slices/managerSlice';

export default function CreateNewManagerDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenCreateNewManagerDialog)
    const allAirports = useSelector(selectAllAirports)
    const allTerminals = useSelector(selectAllTerminals)
    const [selectedTerminals, setselectedTerminals] = useState([])

    const RegisterSchema = Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        email: Yup.string().email("Email must be a valid email address").required('Email is required'),
        password: Yup.string().required('Password is required'),
        airport: Yup.string().required('Airport is required'),
        terminals: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            airport: '',
            terminals: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setFieldError, setSubmitting, resetForm }) => {
            if (selectedTerminals.length == 0) {
                setFieldError('terminals', 'Terminals are required')
            } else {
                let targetTerminals = []
                allTerminals.map(terminal => {
                    if (selectedTerminals.indexOf(terminal.name) != -1) {
                        targetTerminals.push(terminal._id)
                    }
                })

                setSubmitting(true);

                await dispatch(CreateManager({
                    'name': values.fullName,
                    'email': values.email,
                    'password': values.password,
                    'airportId': values.airport,
                    'terminals': targetTerminals,
                    'resetForm': resetForm
                }))

                setSubmitting(false);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm, setFieldError } = formik;


    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenCreateNewManagerDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Create New Manager"}</Typography>

                        <Box >
                            <IconButton onClick={() => { dispatch(updateOpenCreateNewManagerDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <TextField
                                    fullWidth
                                    name="fullName"
                                    label="Full Name"
                                    color='primary'
                                    {...getFieldProps('fullName')}
                                    error={Boolean(touched.fullName && errors.fullName)}
                                    helperText={touched.fullName && errors.fullName}
                                />

                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    color='primary'
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <TextField
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    color='primary'
                                    {...getFieldProps('password')}
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                />

                                <TextField
                                    select
                                    value={values.airport}
                                    label="Airport"
                                    {...getFieldProps('airport')}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setFieldValue('airport', e.target.value);
                                    }}
                                    error={Boolean(touched.airport && errors.airport)}
                                >
                                    {
                                        allAirports.map((singleAirportData, index) => {
                                            return <MenuItem key={`CNMD_airport_${index}`} value={singleAirportData._id}>{singleAirportData.name}</MenuItem>
                                        })
                                    }
                                </TextField>

                                <FormControl>
                                    <InputLabel id="terminals-label">Terminals</InputLabel>
                                    <Select
                                        labelId="terminals-label"
                                        multiple
                                        value={selectedTerminals}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setFieldError('terminals', null)
                                            setselectedTerminals(oldState => ([...e.target.value]))
                                        }}
                                        input={<OutlinedInput fullWidth label="Terminals" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        error={Boolean(touched.terminals && errors.terminals)}
                                    >
                                        {allTerminals.filter(terminal => terminal.airport?._id == values.airport).map((terminal, index) => (
                                            <MenuItem key={`CNMD_terminal_${index}`} value={terminal.name}>
                                                <Checkbox checked={selectedTerminals.indexOf(terminal.name) == -1 ? false : true} />
                                                <ListItemText primary={terminal.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <LoadingButton type="submit" size='large' variant="contained" sx={{ marginRight: '0.5rem' }} loading={isSubmitting} onClick={submitForm}>
                                    Create
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}