import io from "socket.io-client";
import { socketURL } from './backendServerBaseURL'

export default class socketHelper {
  // socket constructor
  constructor() {
    this.socket = io(socketURL, {
      transports: ["websocket"],
      query: {
        type: "superadmin",
      },
    });
  }
}
