import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CreateNewManagerDialog from "../components/Managers/CreateNewManagerDialog";
import DeleteManagerDialog from "../components/Managers/DeleteManagerDialog";
import EditManagerDialog from "../components/Managers/EditManagerDialog";
import ManagersTable from "../components/Managers/ManagersTable";
import { loadAllAirports } from "../redux/slices/airportSlice";
import { updateOpenCreateNewManagerDialog } from "../redux/slices/managerSlice";

export default function Managers({ socket }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllAirports());

    socket.emit('getManagersCurrentStatus')
  }, [])

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item xs={12} md={12}>
          <Button variant="contained" onClick={() => {
            dispatch(updateOpenCreateNewManagerDialog(true));
          }}>Create New Manager</Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <ManagersTable socket={socket} />
        </Grid>
      </Grid>

      {/* Dialogs */}
      <CreateNewManagerDialog />
      <DeleteManagerDialog />
      <EditManagerDialog />
    </>
  );
}
