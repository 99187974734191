import { Navigate, useLocation, useRoutes } from "react-router-dom";
import Managers from "../pages/Managers";
import Airport from "../pages/Airport";
import AirportProfile from "../pages/AirportProfile";
import Dashboard from "../pages/Dashboard";
import Dashboardpage from "../pages/Dashboardpage";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import Page404 from "../pages/Page404";


export default function Router({ socket }) {
  function RequireAuth({ children }) {
    const accessToken = localStorage.getItem("token", null);
    const location = useLocation()

    if (accessToken == null || accessToken == undefined) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  return useRoutes([
    {
      path: "dashboard",
      element: <Dashboard />,
      children: [
        { path: "", element: <RequireAuth><Dashboardpage /></RequireAuth> },
        { path: "dashboardpage", element: <RequireAuth><Dashboardpage /></RequireAuth> },

        { path: "Managers", element: <RequireAuth><Managers socket={socket} /></RequireAuth> },

        { path: "airport", element: <RequireAuth><Airport /></RequireAuth> },

        { path: "airportprofile/:airportId", element: <RequireAuth><AirportProfile /></RequireAuth> },
      ],
    },

    {
      path: "/",
      element: <Login />,
      children: [],
    },

    {
      path: "404",
      element: <Page404 />,
      children: [],
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },

    {
      path: "login",
      element: <Login />,
      children: [],
    },

  ]);
}
