import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { createTerminal, selectOpenCreateNewTerminalDialog, updateOpenCreateNewTerminalDialog } from '../../redux/slices/singleAirportSlice';

export default function CreateNewTerminalDialog() {
    const params = useParams()

    const dispatch = useDispatch()
    const open = useSelector(selectOpenCreateNewTerminalDialog)

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            setSubmitting(true);

            await dispatch(createTerminal({
                'name': values.name,
                'airportId': params.airportId,
                'resetForm': resetForm
            }))

            setSubmitting(false);
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm } = formik;


    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenCreateNewTerminalDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Create New Terminal"}</Typography>

                        <Box >
                            <IconButton onClick={() => { dispatch(updateOpenCreateNewTerminalDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>


                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Terminal Name"
                                    color='primary'
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />

                                <LoadingButton type="submit" size='large' variant="contained" sx={{ marginRight: '0.5rem' }} loading={isSubmitting} onClick={submitForm}>
                                    Create
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}