import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AirportsTable from "../components/Airport/AirportsTable";
import CreateNewAirportDialog from "../components/Airport/CreateNewAirportDialog";
import EditAirportDialog from "../components/Airport/EditAirportDialog";
import { loadAllAirports, updateOpenCreateNewAirportDialog } from "../redux/slices/airportSlice";

export default function Airport() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllAirports())
  }, [])

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item xs={12} md={12}>
          <Button variant="contained" onClick={() => {
            dispatch(updateOpenCreateNewAirportDialog(true));
          }}>Create New Airport</Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <AirportsTable />
        </Grid>
      </Grid>

      {/* Dialogs */}
      <CreateNewAirportDialog />
      <EditAirportDialog />
    </>
  );
}
