import {
  Box,
  Card, Divider, Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectAllTerminals,
  selectTerminalsTablepage,
  selectTerminalsTablepagesCount
} from "../../redux/slices/singleAirportSlice";
import { formatTime } from '../../utils/formatTime';

function SingleAirportTerminalsTable() {
  const params = useParams()
  const allTerminals = useSelector(selectAllTerminals)
  const page = useSelector(selectTerminalsTablepage)
  const pagesCount = useSelector(selectTerminalsTablepagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Created on</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {allTerminals.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{formatTime(row.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default SingleAirportTerminalsTable;
