import {
    Box, Button, Card, CardHeader, Divider, Pagination, Stack, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadSessionsData, updateSelectedSession, updateOpenSessionLogsDialog, selectAllTerminals, selectSessionsData, selectSessionsTablepagesCount, updateSessionsTablepage } from "../../redux/slices/singleAirportSlice";
import { formatTime } from "../../utils/formatTime";
import { staticBaseURL } from '../../utils/backendServerBaseURL';

export default function AirportSessions() {
    const dispatch = useDispatch()
    const sessionsData = useSelector(selectSessionsData)
    const allTerminals = useSelector(selectAllTerminals)
    const params = useParams()
    const [terminalId, setTerminalId] = useState('all')
    const [filter, setFilter] = useState('last 7 days')
    const pagesCount = useSelector(selectSessionsTablepagesCount)

    const handleFilterChange = (event) => {
        dispatch(updateSessionsTablepage(1))
        setFilter(event.target.value)
        dispatch(loadSessionsData({ 'airportId': params.airportId, terminalId: terminalId, filter: event.target.value }))
    };

    const handleTerminalChange = (event) => {
        dispatch(updateSessionsTablepage(1))
        setTerminalId(event.target.value)
        dispatch(loadSessionsData({ 'airportId': params.airportId, terminalId: event.target.value, filter: filter }))
    };

    const handlePageChange = (event, value) => {
        dispatch(updateSessionsTablepage(value))
        dispatch(loadSessionsData({ 'airportId': params.airportId, terminalId: terminalId, filter: filter }))
    };

    return (
        <Card>
            <CardHeader
                title="Sessions"
                subheader=""
                action={
                    <Stack direction={'row'}>
                        <TextField
                            select
                            fullWidth
                            SelectProps={{ native: true }}
                            onChange={handleTerminalChange}
                            sx={{
                                marginRight: 2,
                                width: 150,
                                "& fieldset": { border: "0 !important" },
                                "& select": {
                                    pl: 1,
                                    py: 0.5,
                                    pr: "24px !important",
                                    typography: "subtitle2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 0.75,
                                    bgcolor: "background.neutral",
                                },
                                "& .MuiNativeSelect-icon": {
                                    top: 4,
                                    right: 0,
                                    width: 20,
                                    height: 20,
                                },
                            }}
                        >
                            <option value={'all'} selected>
                                All
                            </option>

                            {
                                allTerminals?.map(terminal => {
                                    return <option value={terminal._id}>
                                        {terminal.name}
                                    </option>
                                })
                            }

                        </TextField>

                        <TextField
                            select
                            fullWidth
                            SelectProps={{ native: true }}
                            onChange={handleFilterChange}
                            sx={{
                                width: 150,
                                "& fieldset": { border: "0 !important" },
                                "& select": {
                                    pl: 1,
                                    py: 0.5,
                                    pr: "24px !important",
                                    typography: "subtitle2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 0.75,
                                    bgcolor: "background.neutral",
                                },
                                "& .MuiNativeSelect-icon": {
                                    top: 4,
                                    right: 0,
                                    width: 20,
                                    height: 20,
                                },
                            }}
                        >
                            <option value={'last 24 hours'}>
                                Last 24 hours
                            </option>
                            <option value={'last 7 days'} selected={true}>
                                Last 7 days
                            </option>
                            <option value={'last month'}>
                                Last Month
                            </option>
                            <option value={'this year'} >
                                This year
                            </option>
                        </TextField>
                    </Stack>

                }
            />

            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Logs</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Airport</TableCell>
                                <TableCell>Terminal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sessionsData?.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell>{row._id}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>
                                        {
                                            row.actions.length !== 0 && <Button variant='contained' onClick={() => {
                                                dispatch(updateSelectedSession(row))
                                                dispatch(updateOpenSessionLogsDialog(true))
                                            }}>View logs</Button>
                                        }

                                    </TableCell>
                                    <TableCell>{formatTime(row.createdAt)}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.airport.name}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.terminal.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider />

                {
                    pagesCount > 1 && <Box p={2}>
                        <Pagination count={pagesCount} color="primary" onChange={handlePageChange} />
                    </Box>
                }
            </Box>
        </Card>
    );
}
