import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DevicesIcon from "@mui/icons-material/Devices";
import LuggageIcon from '@mui/icons-material/Luggage';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Grid } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import AirportCalls from '../components/AirportProfile/AirportCalls';
import AirportSessions from '../components/AirportProfile/AirportSessions';
import CreateNewTerminalDialog from "../components/AirportProfile/CreateNewTerminalDialog";
import FeedbacksGraph from '../components/AirportProfile/FeedbacksGraph';
import SessionLogsDialog from '../components/AirportProfile/SessionLogsDialog';
import SingleAirportManagersTable from '../components/AirportProfile/SingleAirportManagersTable';
import SingleAirportTerminalsTable from "../components/AirportProfile/SingleAirportTerminalsTable";
import WeeklyInstalls from '../components/Dashboardpage/WeeklyInstalls';
import { loadAirportInfo, loadAllManagers, loadAllTerminals, loadCallsData, loadFeedbacksData, loadSessionsData, selectAirportInfo, updateOpenCreateNewTerminalDialog } from "../redux/slices/singleAirportSlice";



export default function AirportProfile() {
  const dispatch = useDispatch()
  const airportInfo = useSelector(selectAirportInfo)
  const params = useParams()

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(loadAirportInfo({ 'airportId': params.airportId }))
    dispatch(loadAllManagers({ 'airportId': params.airportId }))
    dispatch(loadAllTerminals({ 'airportId': params.airportId }))
    dispatch(loadCallsData({ 'airportId': params.airportId, terminalId: 'all', filter: 'last 7 days' }))
    dispatch(loadSessionsData({ 'airportId': params.airportId, terminalId: 'all', filter: 'last 7 days' }))
    dispatch(loadFeedbacksData({ 'airportId': params.airportId, terminalId: 'all', filter: 'last 7 days' }))
  }, [])

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {/* general airport info */}
        <Grid item xs={12}>
          <Typography variant='h2'>{airportInfo?.airport?.name}</Typography>
          <Typography variant='body2' color='text.secondary'>{airportInfo?.airport?.city} - {airportInfo?.airport?.ICAO}</Typography>
        </Grid>

        {/* Stats */}
        <Grid item xs={12} md={3}>
          <WeeklyInstalls
            icon={
              <LuggageIcon height={24} width={24} />
            }
            value={airportInfo.terminals}
            label={'Total Terminals'}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <WeeklyInstalls
            icon={
              <PersonIcon height={24} width={24} />
            }
            value={airportInfo.agents}
            label={'Total Agents'}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <WeeklyInstalls
            icon={
              <DevicesIcon height={24} width={24} />
            }
            value={airportInfo.kiosks}
            label={'Total Kiosks'}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <WeeklyInstalls
            icon={
              <AdminPanelSettingsIcon height={24} width={24} />
            }
            value={airportInfo.managers}
            label={'Total Managers'}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Terminals" />
              <Tab label="Managers" />
              <Tab label="Feedbacks" />
              <Tab label="Calls" />
              <Tab label="Sessions" />
            </Tabs>
          </Box>

          <Box p={2}>
            {/* Terminals */}
            <Box sx={{ display: value === 0 ? 'block' : 'none' }}>
              <Button variant='contained' sx={{ marginBottom: 1 }} onClick={() => { dispatch(updateOpenCreateNewTerminalDialog(true)) }}>Create New Terminal</Button>
              <SingleAirportTerminalsTable />
            </Box>

            {/* Managers */}
            <Box sx={{ display: value === 1 ? 'block' : 'none' }}>
              <SingleAirportManagersTable />
            </Box>

            {/* Feedbacks */}
            <Box sx={{ display: value === 2 ? 'block' : 'none' }}>
              <FeedbacksGraph />
            </Box>

            {/* Calls */}
            <Box sx={{ display: value === 3 ? 'block' : 'none' }}>
              <AirportCalls />
            </Box>

            {/* Sessions */}
            <Box sx={{ display: value === 4 ? 'block' : 'none' }}>
              <AirportSessions />
            </Box>
          </Box>
        </Grid>

      </Grid>

      {/* Dialogs */}
      <CreateNewTerminalDialog />
      <SessionLogsDialog />
    </>
  );
}
