import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  allAirports: [],
  selectedAirport: null,

  // Dialog
  openCreateNewAirportDialog: false,
  openEditAirportDialog: false,

  // Pagination
  page: 1,
  pagesCount: 1,
};

export const createAirport = createAsyncThunk(
  'airport/createAirport',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      'name': payload.name,
      'city': payload.city,
      'ICAO': payload.icao
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/admin/airport/create`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenCreateNewAirportDialog(false))
      thunkAPI.dispatch(loadAllAirports())
      payload.resetForm()
    }
  }
);

export const updateAirport = createAsyncThunk(
  'airport/updateAirport',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      'name': payload.name,
      'city': payload.city,
      'ICAO': payload.icao
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/admin/airport/update/${thunkAPI.getState().airport.selectedAirport._id}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenEditAirportDialog(false))
      thunkAPI.dispatch(loadAllAirports())
    }
  }
);

export const loadAllAirports = createAsyncThunk(
  'airport/loadAllAirports',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/admin/airport/read`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllAirports(response.data.payload))
    }
  }
);

export const airportSlice = createSlice({
  name: 'airport',
  initialState,
  reducers: {
    updateAllAirports: (state, action) => {
      state.allAirports = action.payload;
    },
    updateSelectedAirport: (state, action) => {
      state.selectedAirport = action.payload;
    },

    // Dialog
    updateOpenCreateNewAirportDialog: (state, action) => {
      state.openCreateNewAirportDialog = action.payload;
    },
    updateOpenEditAirportDialog: (state, action) => {
      state.openEditAirportDialog = action.payload;
    },
  }
})

export const {
  updateAllAirports,
  updateSelectedAirport,

  updateOpenCreateNewAirportDialog,
  updateOpenEditAirportDialog
} = airportSlice.actions;

export const selectAllAirports = (state) => state.airport.allAirports;
export const selectPage = (state) => state.airport.page;
export const selectPagesCount = (state) => state.airport.pagesCount;
export const selectSelectedAirport = (state) => state.airport.selectedAirport;

export const selectOpenCreateNewAirportDialog = (state) => state.airport.openCreateNewAirportDialog;
export const selectOpenEditAirportDialog = (state) => state.airport.openEditAirportDialog;


export default airportSlice.reducer;
