import ThemeConfig from "./theme";
import Router from "./routes";
import socketHelper from './utils/socketHelper';
import { useEffect } from "react";

let socket = new socketHelper().socket;

socket.on("me", (id) => {
  console.log('My socket ID - ', id);
  localStorage.setItem('mySocketId', id)
});

function App() {
  return (
    <>
      <ThemeConfig>
        <Router socket={socket} />
      </ThemeConfig>
    </>
  );
}

export default App;
