import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FlightIcon from '@mui/icons-material/Flight';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarGraph from "../components/Dashboardpage/BarGraph";
import WeeklyInstalls from "../components/Dashboardpage/WeeklyInstalls";
import { loadDashboardStats, selectDashboardStats } from "../redux/slices/dashboardSlice";

export default function Dashboardpage() {
  const dashboardStats = useSelector(selectDashboardStats)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadDashboardStats({ filter: 'last 7 days' }))
  }, [])

  return (
    <Grid
      container
      spacing={3}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      {/* Stats */}
      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <FlightIcon height={24} width={24} />
          }
          value={dashboardStats?.stats.total_airports}
          label={'Total Airports'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <PhoneAndroidIcon height={24} width={24} />
          }
          value={dashboardStats?.stats.total_kiosks}
          label={'Total Kiosks'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <AdminPanelSettingsIcon height={24} width={24} />
          }
          value={dashboardStats?.stats.total_managers}
          label={'Total Managers'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <AdminPanelSettingsIcon height={24} width={24} />
          }
          value={dashboardStats?.stats.total_terminals}
          label={'Total Terminals'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <PersonIcon height={24} width={24} />
          }
          value={dashboardStats?.stats.total_agents}
          label={'Total Agents'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <LocalPhoneIcon height={24} width={24} />
          }
          value={dashboardStats?.stats.total_calls}
          label={'Total Calls'}
        />
      </Grid>

      {/* Calls */}
      <Grid item xs={12} md={12}>
        {
          dashboardStats != null && <BarGraph dashboardStats={dashboardStats} />
        }

      </Grid>
    </Grid>
  );
}
